import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Social from "../components/social"

import "./styles/blog-post.css";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  //const { previous, next } = pageContext;


  return (
    <Layout location={location} title={siteTitle}>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: `var(--maxWidth-4xl)`,
          padding: `0 var(--spacing-5)`
        }}
      >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div
        style={{
          backgroundColor: 'var(--bg-reading)',
          margin: `0 auto`,
          maxWidth: `var(--maxWidth-3xl)`,
          padding: `var(--spacing-16)`
        }}>
      <article
        style={{
          marginBottom: `var(--spacing-4)`,
          marginTop: `var(--spacing-4)`
        }}
        >
        <header>
          <h1
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
            className="headline-text"
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              display: `block`,
              marginBottom: `var(--spacing-4)`,
            }}
          >
            {post.frontmatter.date}
          </p>
          <figure>
            <Image
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
              alt={post.frontmatter.featuredImageAlt}
              style={{
                width: `100%`
              }}
            />
            <figcaption
              style={{
                fontSize: `var(--fontSize-0)`,
                fontStyle: 'italic',
                textAlign: 'center',
                textDecoration: 'underline',
              }}
              ><a href={post.frontmatter.featuredImageCaptionLink}><p>{post.frontmatter.featuredImageCaption}</p></a></figcaption>

          </figure>

          <Social
            url={location.href}
            title={siteTitle}
          />


        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: `var(--spacing-4)`,
          }}
         />
         <Social
           url={location.href}
           title={siteTitle}
         />
         <hr
           style={{
             marginBottom: `var(--spacing-4)`,
           }}
          />
        <footer>
          <Bio />
        </footer>
      </article>
    </div>
  </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageAlt
        featuredImageCaption
        featuredImageCaptionLink
      }
    }
  }
`
