import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"


const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/gdickens-profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 75, height: 75, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        display: `flex`
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt='Greg Dickens'
        style={{
          marginRight: `var(--spacing-4)`,
          marginBottom: 0,
          minWidth: 75,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        <strong>Greg Dickens</strong> grew up in a small town of less than 2,000 people in rural New York State.  After a decade working in finance and technology, he's now taking everything he has learned to create new opportunities for the people he grew up with by building digital tools that help local communities.  You can check out his work <a href="https://www.epilocal.com">here.</a>
      </p>
    </div>
  )
}

export default Bio
