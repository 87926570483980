import React from 'react';

import {
  EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	RedditShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
} from 'react-share';


const Social = ({ url, title }) => (
  <div
    url={url}
    style={{
      textAlign: `center`,
      width: '100%'
    }}
    >
    <FacebookShareButton
      url={url}
      style={{
        margin: '0 0.25rem'
      }}  >
      <FacebookIcon size={32} round={true} />
    </FacebookShareButton>
    <TwitterShareButton
      url={url}
      style={{
        margin: '0 0.25rem'
      }}  >
      <TwitterIcon size={32} round={true} />
    </TwitterShareButton>
    <LinkedinShareButton
      url={url}
      title={title}
      style={{
        margin: '0 0.25rem'
      }}  >
      <LinkedinIcon size={32} round={true} />
    </LinkedinShareButton>
    <RedditShareButton
      url={url}
      title={title}
      style={{
        margin: '0 0.25rem'
      }}  >
      <RedditIcon size={32} round={true} />
    </RedditShareButton>
    <EmailShareButton
      url={url}
      title={title}
      style={{
        margin: '0 0.25rem'
      }}  >
      <EmailIcon size={32} round={true} />
    </EmailShareButton>
  </div>
)



export default Social;
